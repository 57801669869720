@import "variables/colors";

.carrot-orange {
  color: $carrot-orange;
}
.bg-carrot-orange {
  background-color: $carrot-orange;
}
.bg-carrot-orange-05 {
  background-color: rgba(228, 132, 0, 0.05);
}
.bg-carrot-orange-01 {
  background-color: rgba(228, 132, 0, 0.1);
}

.hover-bg-carrot-orange-05:hover,
.hover-bg-carrot-orange-05:focus {
  background-color: rgba(228, 132, 0, 0.05);
}

.bg-carrot-orange-35 {
  background-color: rgba(228, 132, 0, 0.35);
}

.b--carrot-orange {
  border-color: $carrot-orange;
}

.hover-b--carrot-orange:hover,
.hover-b--carrot-orange:focus {
  border-color: $carrot-orange;
}

.b--carrot-orange-35 {
  border-color: rgba(228, 132, 0, 0.35);
}

.hover-carrot-orange:hover,
.hover-carrot-orange:focus {
  color: $carrot-orange;
}

.hover-bg-carrot-orange:hover,
.hover-bg-carrot-orange:focus {
  background-color: $carrot-orange;
}

.light-carrot-orange {
  color: $light-carrot-orange;
}
.bg-light-carrot-orange {
  background-color: $light-carrot-orange;
}
.b--light-carrot-orange {
  border-color: $light-carrot-orange;
}

.very-light-carrot-orange {
  color: $very-light-carrot-orange;
}
.bg-very-light-carrot-orange {
  background-color: $very-light-carrot-orange;
}
.b--very-light-carrot-orange {
  border-color: $very-light-carrot-orange;
}

.deep-carrot-orange {
  color: $deep-carrot-orange;
}
.bg-deep-carrot-orange {
  background-color: $deep-carrot-orange;
}
.b--deep-carrot-orange {
  border-color: $deep-carrot-orange;
}

.dark-carrot-orange {
  color: $dark-carrot-orange;
}
.bg-dark-carrot-orange {
  background-color: $dark-carrot-orange;
}
.b--dark-carrot-orange {
  border-color: $dark-carrot-orange;
}

.bg-carrot-medium-green {
  background-color: #b2d1bd;
}

.bg-carrot-green {
  background-color: $carrot-green;
}

.b--carrot-green {
  border-color: $carrot-green;
}

.b--brownish-gray {
  border-color: $brownish-gray;
}

.carrot-blue {
  color: $carrot-blue;
}

.bg-carrot-blue {
  background-color: $carrot-blue;
}

.b--carrot-blue {
  border-color: $carrot-blue;
}
.b--mineral-grey {
  border-color: $mineral-grey;
}

.carrot-red {
  color: $carrot-red;
}
.invalid-red {
  color: $invalid-red;
}
.b--invalid-red {
  border-color: $invalid-red;
}

.bg-carrot-red {
  background-color: $carrot-red;
}

.b--carrot-red {
  border-color: $carrot-red;
}

.bg-modal {
  background-color: rgba(181, 173, 166, 0.6);
}

.bg-benefit-started-modal {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-modal-dark {
  background-color: rgba(181, 173, 166, 0.9);
}

.bg-semi-transparent-white {
  background-color: rgba(255, 255, 255, 0.7);
}


.bg-old-lace{
  background-color: $old-lace;
}

.hover-bg-old-lace:hover {
  background-color: $old-lace
}

.hover-bg-black-05:hover,
.hover-bg-black-05:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.hover-bg-black:hover,
.hover-bg-black:focus {
  background-color: black;
}

.hover-b--black:hover,
.hover-b--black:focus {
  border-color: black;
}

.hover-black-40:hover,
.hover-black-40:focus {
  color: rgba(0, 0, 0, 0.4);
}

.old-rose {
  color: $old-rose;
}

.bg-old-rose {
  background-color: $old-rose;
}

.b--old-rose {
  border-color: $old-rose;
}

.hover-bg-dark-old-rose:hover,
.hover-bg-dark-old-rose:focus {
  background-color: $dark-old-rose;
}

.bg-spring-wood {
  background-color: $spring-wood;
}

.b--spring-wood {
  border-color: $spring-wood;
}

.bg-pampas {
  background-color: $pampas;
}

.b--pampas {
  border-color: $pampas;
}

.bg-light-pampas {
  background-color: $light-pampas;
}

.bg-dark-pampas {
  background-color: $dark-pampas;
}

.bg-shuttle-gray {
  background-color: $shuttle-gray;
}

.fiord {
  color: $fiord;
}

.dark-old-rose {
  color: $dark-old-rose;
}

.bg-fiord {
  background-color: $fiord;
}

.hover-bg-dark-fiord:hover,
.hover-bg-dark-fiord:focus {
  background-color: $dark-fiord;
}

.geyser {
  color: $geyser;
}

.bg-geyser {
  background-color: $geyser;
}

.puce {
  color: $puce;
}

.bg-puce {
  background-color: $puce;
}

.bg-green-white {
  background-color: $green-white;
}

.bg-mystic {
  background-color: $mystic;
}

.bg-pearl-lusta {
  background-color: $pearl-lusta;
}

.striped--pearl-lusta:nth-child(even) {
  background-color: $pearl-lusta;
}

.bg-linen {
  background-color: $linen;
}

.bg-carrot-green {
  background-color: $carrot-green;
}

.bg-light-carrot-green {
  background-color: $light-carrot-green;
}

.bg-carrot-green-stripe {
  background-image: url(sea-nymph-stripe-pattern.png);
  background-size: 4px 4px;
  background-repeat: repeat;
  background-position: -1px -1px;
}

.carrot-green {
  color: $carrot-green;
}

.light-carrot-green {
  color: $light-carrot-green;
}

.sea-peach {
  color: $sea-peach;
}

.bg-sea-peach {
  background-color: $sea-peach;
}

.hover-bg-dark-carrot-green:hover,
.hover-bg-dark-carrot-green:focus {
  background-color: $dark-carrot-green;
}

::placeholder {
  color: currentColor;
}

.hover-b--gray:hover,
.hover-b--gray:focus,
.hover-b--gray:focus {
  border-color: $gray;
}

.hover-dark-carrot-orange:hover,
.hover-dark-carrot-orange:focus {
  color: $dark-carrot-orange;
}

.hover-bg-dark-carrot-orange:hover,
.hover-bg-dark-carrot-orange:focus {
  background-color: $dark-carrot-orange;
}

.hover-b--dark-carrot-orange:hover,
.hover-b--dark-carrot-orange:focus {
  border-color: $dark-carrot-orange;
}

.hover-bg-very-light-carrot-orange:hover,
.hover-bg-very-light-carrot-orange:focus {
  background-color: $very-light-carrot-orange;
}

.hover-b--very-light-carrot-orange:hover,
.hover-b--very-light-carrot-orange:focus {
  border-color: $very-light-carrot-orange;
}

.hover-bg-light-gray:hover,
.hover-bg-light-gray:focus {
  background-color: $light-gray;
}

/* product redesign colors */
.mint {
  color: $mint;
}

.bg-mint {
  background-color: $mint;
}

.mint-alternate {
  color: $mint-alternate;
}

.bg-mint-alternate {
  background-color: $mint-alternate;
}

.b--mint-alternate{
  border-color: $mint-alternate;
}
.bg-mint-alternate-lite {
  background-color: #EDF5F3;
}

.dark-mint {
  color: $dark-mint;
}

.bg-dark-mint {
  background-color: $dark-mint;
}

.bright-green {
  color: $bright-green;
}

.bg-bright-green {
  background-color: $bright-green;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-mineral-grey {
  background-color: $mineral-grey;
}

.b--mineral-grey {
  border-color: $mineral-grey;
}

.bg-warm-gray {
  background-color: $warm-gray;
}

.mineral-grey {
  background-color: #d9d9d9;
}

.light-gray {
  color: $light-gray;
}

.bg-light-grey {
  background-color: $light-gray;
}

.bg-icon-grey {
  background-color: $icon-grey;
}
.bg-strong-cyan-blue {
  background-color: $strong-cyan-blue;
}

.bg-dark-warm-gray {
  background-color: $dark-warm-gray;
}
.bg-red-orange-white {
  background-color: $red-orange-white;
}

.bg-roman-red {
  background-color: $roman-red;
}

.bg-roman-red-40 {
  background-color: rgba($roman-red, 0.4);
}

// talk to carrot link
.talk-to-carrot-hover .talk-to-carrot-dim {
  background-color: white;
  color: $carrot-orange;
}

.talk-to-carrot-hover:hover .talk-to-carrot-dim {
  background-color: $dark-carrot-orange;
  color: white;
}

.talk-to-carrot-hover:focus .talk-to-carrot-dim {
  background-color: $carrot-orange;
  color: white;
}

.bg-pattens-blue {
  background-color: $pattens-blue;
}

@import "../../node_modules/@carrot-fertility/tachyons";
@import "variables/breakpoints";
@import "variables/colors";

@import "fonts";
@import "colors.scss";
@import "form.css";
@import "tippy.scss";
@import "benefits.css";
@import "calendly.scss";

/**
* Note: The order in which these classes are placed in this file matters for precedence to work properly,
*       All classes for Not-Small(-ns) should be placed after the classes for small layout
*/

body {
  block-size: initial !important;
}

.sidebar {
  will-change: min-height;
}

.sidebar__inner {
  transform: translate(0, 0); /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}

body {
  font-family: $gotham;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m0-first-last-child > :first-child {
  margin-block-start: 0px;
}

.m0-first-last-child > :last-child {
  margin-block-end: 0px;
}

.cc {
  color: currentColor;
}

input::placeholder {
  color: #00000066;
}

.placeholder-black-50::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.placeholder-black-20::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.color-dark-blue {
  color: $dark-blue;
}

.inset-shadow {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.shadow-hover:hover,
.shadow-hover:focus {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/* reset shadow-hover from tachyons */
.shadow-hover::after {
  box-shadow: none;
}

.shadow-hover:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.shadow-modal {
  box-shadow: 0 15px 35px rgba(58, 75, 82, 0.25);
}

.shadow-inset {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pointer-events {
  pointer-events: auto;
}

.no-pointer-events {
  pointer-events: none;
}

.not-allowed {
  cursor: not-allowed;
}

.resize-vertical {
  resize: block;
}

/* TODO: make svg path for clipping*/
.triangle-up {
  clip-path: polygon(0% 100%, 100% 100%, 50% 0%);
}
/* heights & widths*/
.h--1 {
  block-size: 0.25rem;
}
.h0 {
  block-size: 0.5rem;
}
.h15 {
  block-size: 1.5rem;
}
.w15 {
  inline-size: 1.5rem;
}
.h25 {
  block-size: 3rem;
}
.w25 {
  inline-size: 3rem;
}
.h35 {
  block-size: 6rem;
}
.min-w25 {
  min-inline-size: 3rem;
}
.min-w15 {
  min-inline-size: 1.5rem;
}

.min-w250px {
  min-inline-size: 250px;
}

.min-w150px {
  min-inline-size: 150px;
}

.w35 {
  inline-size: 6rem;
}

.h45 {
  block-size: 12rem;
}
.w45 {
  inline-size: 12rem;
}

.mw55 {
  max-inline-size: 24rem;
}

.mw65 {
  max-inline-size: 40rem;
}
.mw75 {
  max-inline-size: 56rem;
}

.mw85 {
  max-inline-size: 80rem;
}

@media #{$breakpoint-medium} {
  .w200px-m {
    inline-size: 200px;
  }
}

.min-w65 {
  min-inline-size: 48rem;
}

.w-infinite {
  inline-size: 10000px;
}

.mw-1280 {
  max-inline-size: 100vi;
}
.mw-1080 {
  max-inline-size: 1080px;
}

.min-w3 {
  min-inline-size: 4rem;
}
.min-w4 {
  min-inline-size: 8rem;
}

.min-w5 {
  min-inline-size: 16rem;
}

@media screen and (min-width: 1280px) {
  .mw-1280 {
    max-inline-size: 1280px;
  }
}

.vw-100 {
  inline-size: 100vi;
}

@media #{$breakpoint-small} {
  .vw-100-s {
    inline-size: 100vi;
  }
}

.mv35 {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
}

.mt35 {
  margin-block-start: 1.5rem;
}
@media #{$breakpoint-small} {
  .mt35-s {
    margin-block-start: 1.5rem;
  }

  .ph3-s {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
  }
}

.mb35 {
  margin-block-end: 1.5rem;
}
@media #{$breakpoint-small} {
  .mb35-s {
    margin-block-end: 1.5rem;
  }
}

.mb55 {
  margin-block-end: 6rem;
}

@media #{$breakpoint-small} {
  .mr-80px-s {
    margin-inline-end: 80px;
  }
}

@media #{$breakpoint-large} {
  .mr35-l {
    margin-inline-end: 1.5rem;
  }

  .mt25-l {
    margin-block-start: 2.5rem;
  }
}
.mt45 {
  margin-block-start: 3rem;
}

.pt35 {
  padding-block-start: 1.5rem;
}

.ph25 {
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
}
@media #{$breakpoint-medium} {
  .ph25-m {
    padding-inline-start: 0.75rem;
    padding-inline-end: 0.75rem;
  }
}
@media #{$breakpoint-small} {
  .ph25-s {
    padding-inline-start: 0.75rem;
    padding-inline-end: 0.75rem;
  }
}
.pv25 {
  padding-block-start: 0.75rem;
  padding-block-end: 0.75rem;
}

@media #{$breakpoint-medium} {
  .pv25-m {
    padding-block-start: 0.75rem;
    padding-block-end: 0.75rem;
  }
}

.mh35 {
  margin-inline-start: 1.5rem;
  margin-inline-end: 1.5rem;
}
@media #{$breakpoint-small} {
  .mh35-s {
    margin-inline-start: 1.5rem;
    margin-inline-end: 1.5rem;
  }
}

@media #{$breakpoint-large} {
  .nt25-l {
    margin-block-start: -0.75rem;
  }
}

@media #{$breakpoint-large} {
  .nh25-l {
    margin-inline-start: -0.75rem;
    margin-inline-end: -0.75rem;
  }
}

@media #{$breakpoint-medium} {
  .nh35-m {
    margin-inline-start: -1.5rem;
    margin-inline-end: -1.5rem;
  }
}
@media #{$breakpoint-small} {
  .nh35-s {
    margin-inline-start: -1.5rem;
    margin-inline-end: -1.5rem;
  }
}
.nh35 {
  margin-inline-start: -1.5rem;
  margin-inline-end: -1.5rem;
}

.nh25 {
  margin-inline-start: -0.75rem;
  margin-inline-end: -0.75rem;
}

@media #{$breakpoint-small} {
  .mh0-s {
    margin: 0;
  }
}

.mh25 {
  margin-inline-start: 0.75rem;
  margin-inline-end: 0.75rem;
}
.mh35 {
  margin-inline-start: 1.5rem;
  margin-inline-end: 1.5rem;
}
@media #{$breakpoint-medium} {
  .mh35-m {
    margin-inline-start: 1.5rem;
    margin-inline-end: 1.5rem;
  }
}

@media #{$breakpoint-large} {
  .mh25-l {
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
  }
}

.pa25 {
  padding: 0.75rem;
}

.pa35 {
  padding: 1.5rem;
}

.pa45 {
  padding: 3rem;
}

@media #{$breakpoint-medium} {
  .ph35-m {
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
  }
}
.ph35 {
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
}
.ph45 {
  padding-inline-start: 3rem;
  padding-inline-end: 3rem;
}

@media #{$breakpoint-not-small} {
  .pl45-ns {
    padding-inline-start: 3rem;
  }

  .pr45-ns {
    padding-inline-end: 3rem;
  }

  .ph45-ns {
    padding-inline-start: 3rem;
    padding-inline-end: 3rem;
  }

  .pv45-ns {
    padding-block-start: 3rem;
    padding-block-end: 3rem;
  }

  .pb45-ns {
    padding-block-end: 3rem;
  }

  .pa55-ns {
    padding: 6rem;
  }
}

@media #{$breakpoint-large} {
  .ph45-l {
    padding-inline-start: 3rem;
    padding-inline-end: 3rem;
  }

  .pl45-l {
    padding-inline-start: 3rem;
  }

  .pr45-l {
    padding-inline-end: 3rem;
  }

  .pa55-l {
    padding: 6rem;
  }
}

@media #{$breakpoint-medium} {
  .pl45-m {
    padding-inline-start: 3rem;
  }

  .pr45-m {
    padding-inline-end: 3rem;
  }
}

.pr35 {
  padding-inline-end: 1.5rem;
}

.pl35 {
  padding-inline-start: 1.5rem;
}

@media #{$breakpoint-small} {
  .ph35-s {
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
  }

  .pv35-s {
    padding-block-start: 1.5rem;
    padding-block-end: 1.5rem;
  }

  .pl35-s {
    padding-inline-start: 1.5rem;
  }

  .pr35-s {
    padding-inline-end: 1.5rem;
  }

  .pb35-s {
    padding-block-end: 1.5rem;
  }
}

@media #{$breakpoint-large} {
  .ph35-l {
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
  }
  .pl35-l {
    padding-inline-start: 1.5rem;
  }

  .pr35-l {
    padding-inline-end: 1.5rem;
  }
}

.pv35 {
  padding-block-start: 1.5rem;
  padding-block-end: 1.5rem;
}

.pb35 {
  padding-block-end: 1.5rem;
}

.pb45 {
  padding-block-end: 3rem;
}

.pb-100 {
  padding-block-end: 100%;
}

.animate-all {
  transition: all 0.15s cubic-bezier(0.8, 0, 0.2, 1);
}

.animate-all-slow {
  transition: all 0.3s cubic-bezier(0.8, 0, 0.2, 1);
}

.fade-in {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* video embed styles */

.embed-container {
  position: relative;
  padding-block-end: 56.25%;
  block-size: 0;
  overflow: hidden;
  max-inline-size: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
}

.ml-auto {
  margin-inline-start: auto;
}

.cursor-default {
  cursor: default;
}

.column-span {
  column-span: all;
}

.t--fixed {
  table-layout: fixed;
}

.tracked-half {
  letter-spacing: 0.05em;
}

.tracked-01 {
  letter-spacing: 0.01em;
}

/* Loading spinner */
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 100vb;
  inline-size: 100vi;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  inline-size: 64px;
  block-size: 64px;
}
.lds-ellipsis div {
  position: absolute;
  inset-block-start: 27px;
  inline-size: 11px;
  block-size: 11px;
  border-radius: 50%;
  background: $carrot-orange;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  inset-inline-start: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  inset-inline-start: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  inset-inline-start: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  inset-inline-start: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.fullscreen-dropzone-overlay {
  position: fixed;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 99999999;
  opacity: 0;
}

.no-drag {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.w-8px {
  inline-size: 8px;
}
.h-2px {
  block-size: 2px;
}
.f8 {
  font-size: 0.5rem;
}

.f25 {
  font-size: 2rem;
}

@media #{$breakpoint-large} {
  .w-220px-l {
    min-inline-size: 220px;
    inline-size: 220px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}

.mw-600 {
  max-inline-size: 600px;
}

@media #{$breakpoint-medium} {
  .mw-600-m {
    max-inline-size: 600px;
  }
}

@media #{$breakpoint-large} {
  .min-w244px-l {
    min-inline-size: 244px;
  }
}

.mw-320 {
  max-inline-size: 320px;
}

.mw-606 {
  max-inline-size: 606px;
}

.mw-600 {
  max-inline-size: 600px;
}

.mw-800 {
  max-inline-size: 800px;
}

.mw-968 {
  max-inline-size: 968px;
}

@media #{$breakpoint-large} {
  .mw-968-l {
    max-inline-size: 968px;
  }

  .min-w384-l {
    min-inline-size: 384px;
  }

  .min-w232-l {
    min-inline-size: 232px;
  }
}

@media #{$breakpoint-medium} {
  .mw-600-m {
    max-inline-size: 600px;
  }
}

@media #{$breakpoint-small} {
  .min-h-100vh-s {
    min-block-size: 100vb;
  }
}
@media #{$breakpoint-small} {
  .min-w-375-s {
    min-inline-size: 375px;
  }
  .max-w-504-s {
    max-inline-size: 504px;
  }
  .h-96-s {
    block-size: 96px;
  }
}
@media #{$breakpoint-not-small} {
  .min-w-504-ns {
    min-inline-size: 504px;
  }
  .max-w-504-ns {
    max-inline-size: 504px;
  }
  .h-192-ns {
    block-size: 192px;
  }
}

// Custom CSS for subcategory nav
@media screen and (min-width: 60em) and (max-width: 75em) {
  .subcategory-nav {
    inline-size: 50%;
    margin-inline-end: 0;
  }
}

@media screen and (min-width: 37.5em) and (max-width: 53.75em) {
  .subcategory-nav {
    inline-size: 50%;
    margin-inline-end: 0;
  }
}

@media screen and (min-width: 37.5em) and (max-width: 41.25em) {
  .subcategory-nav {
    inline-size: 50%;
    margin-inline-end: 0;
  }
}
@media screen and (min-width: 23.4375em) and (max-width: 37.5em) {
  .subcategory-nav {
    inline-size: 50%;
    margin-inline-end: 0;
  }
}

@media screen and (max-width: 23.4375em) {
  .subcategory-nav {
    inline-size: 100%;
    margin-inline-end: 0;
  }
}

.subcategory-nav {
  padding-block-end: 0.1875rem;
}

.nl35 {
  margin-inline-start: -1.5rem;
}

.nr35 {
  margin-inline-end: -1.5rem;
}

.v-tbtm {
  vertical-align: text-bottom;
}

.v-tbtp {
  vertical-align: text-top;
}

ul {
  list-style: none; /* Remove default bullets */
  margin-inline-start: -1.5em;
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold;
  color: $carrot-orange; /* Change the color */
  display: inline-block; /* Needed to add space between the bullet and the text */
  inline-size: 1em; /* Also needed for space (tweak if needed) */
  margin-inline-start: -1em; /* Also needed for space (tweak if needed) */
}

li > ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: $carrot-orange; /* Change the color */
  display: inline-block; /* Needed to add space between the bullet and the text */
  inline-size: 1em; /* Also needed for space (tweak if needed) */
  margin-inline-start: -1em; /* Also needed for space (tweak if needed) */
  block-size: 24px;
}

.dim:hover,
.dim:focus {
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
}

.b--mineral-grey {
  border-color: $mineral-grey;
}

.bg-coral {
  background-color: $coral;
}

.bg-cornflower-blue {
  background-color: $cornflower-blue;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-nurse-gray {
  background-color: $nurse-gray;
}

.bg-porcelain {
  background-color: $porcelain;
}

.bg-fair-pink {
  background-color: $fair-pink;
}

.devil-gray {
  color: $devil-gray;
}

.hover-parent-fw5:hover .hover-child-fw5 {
  font-weight: 500;
}

@media #{$breakpoint-not-small} {
  .min-w-600-ns {
    min-inline-size: 600px;
  }
}

@media #{$breakpoint-not-small} {
  .ph35-ns {
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
  }

  .pv35-ns {
    padding-block-start: 1.5rem;
    padding-block-end: 1.5rem;
  }

  .pv45-ns {
    padding-block-start: 3rem;
    padding-block-end: 3rem;
  }
}

@media #{$breakpoint-not-small} {
  .ph25-ns {
    padding-inline-start: 0.75rem;
    padding-inline-end: 0.75rem;
  }
}

@media #{$breakpoint-not-small} {
  .mt45-ns {
    margin-block-start: 3rem;
  }
}

@media #{$breakpoint-not-small} {
  .mb45-ns {
    margin-block-end: 3rem;
  }
}

@media #{$breakpoint-not-small} {
  .mt35-ns {
    margin-block-start: 1.5rem;
  }
  .ml35-ns {
    margin-inline-start: 1.5rem;
  }
  .mb35-ns {
    margin-block-end: 1.5rem;
  }
}
@media #{$breakpoint-not-small} {
  .h35-ns {
    block-size: 6rem;
  }
}

@media #{$breakpoint-not-small} {
  .w35-ns {
    inline-size: 6rem;
  }
  .w-18-ns {
    inline-size: 18%;
  }
  .w-15-ns {
    inline-size: 15%;
  }
  .w-29-ns {
    inline-size: 29%;
  }
  .w-45-ns {
    inline-size: 45%;
  }
}

@media #{$breakpoint-not-small} {
  .h45-ns {
    block-size: 12rem;
  }
}

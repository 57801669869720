/* custom css styles for benefits screen */

@media screen and (min-width: 44em) {
  .w-50-44em {
    inline-size: 50%;
  }
  .db-44em {
    display: block;
  }
}

@import "variables/colors";
@import "fonts";

/*
Themes for Tippy

See https://atomiks.github.io/tippyjs/#creating-custom-themes
*/

/*
Shuttle Gray
 */
.tippy-tooltip.shuttle-gray-theme {
  padding: 15px 25px;
  font-family: $gotham;
  font-size: 16px;
  line-height: 36px;
  text-align: start;
  background-color: $shuttle-gray;
}

/* Style the arrow: https://github.com/atomiks/tippyjs/issues/35 */
.tippy-tooltip.shuttle-gray-theme .tippy-arrow {
  border-width: 18px;
  block-size: 18px;
}

/* When tooltip is on bottom and arrow is on top (normal state) */
.tippy-popper[x-placement="bottom"] .tippy-tooltip.shuttle-gray-theme .tippy-arrow {
  inset-block-start: -18px;
  border-block-end-color: $shuttle-gray;
}

/* When tooltip is on top and arrow is on bottom (happens when the browser size is small) */
.tippy-popper[x-placement="top"] .tippy-tooltip.shuttle-gray-theme .tippy-arrow {
  inset-block-end: -18px;
  border-block-start-color: $shuttle-gray;
}

.placeholder {
  transform: translateX(1rem) translateY(1.625rem);
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  transform-origin: top left;
}

.placeholder.move {
  transform: translateX(1rem) translateY(0.875rem) scale(0.75);
}

@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  12.5% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  37.5% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  62.5% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }

  87.5% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.shake {
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-name: shake;
}

.no-outline {
  outline: none;
}

.no-outline-on-focus:focus {
  outline: none;
}

/* if you need to hide something but still have it in the DOM, use this */
.invisible {
  visibility: hidden;
  inline-size: 0;
  block-size: 0;
  overflow: hidden;
}